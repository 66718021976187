import React, { useState, useContext, createContext } from "react"

export const AppContext = React.createContext([{}, () => {}])

const initialState = {
  mousemove: false,
  setMousemove: () => {},
}
const ThemeContext = createContext(initialState)

export const AppProvider = props => {
  const [mousemove, setMousemove] = useState(false)

  return (
    <AppContext.Provider
      value={{
        mousemove,
        setMousemove,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

const useTheme = () => {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider")
  }
  return context
}
export default useTheme
